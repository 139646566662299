.modal {

	&.is-open {
		@apply block relative;
		z-index: 10000;
	}

	&__close {
		@apply absolute top-0 right-0 px-0 w-14 h-14 flex items-center justify-center z-50;

		background: url(/img/svg/close.svg) #FFF200;
		background-size: 20px auto;
		background-position: center;
		background-repeat: no-repeat;

		&:hover {
			@apply bg-black;
			background: url(/img/svg/close-yellow.svg) #000000;
			background-size: 20px auto;
			background-position: center;
			background-repeat: no-repeat;
		}
	}

	&__overlay {
		@apply fixed inset-0 flex justify-center items-center bg-overlayA z-50 ;
	}

	&__container {
		max-height: 100vh;
	}

	&__content {
		@apply bg-white overflow-hidden relative;

		.wistia_embed {
			width: 100% !important;
			height: 100% !important;
			position: absolute !important;
			transform: translate(-50%, -50%);
			@apply max-h-full left-2/4 top-2/4;
		}

		.w-bpb-wrapper,
		.w-big-play-button {

			overflow: hidden;

			div {
				background-color: #FFF200 !important;
				background-image: url('/img/svg/play.svg');
				background-repeat: no-repeat;
				background-position: center;
				background-size: 20%;
			}

			svg {
				display: none !important;
			}

		}
	}
}