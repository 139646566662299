.section-image-text {

	&.bg-ltGrey,
	&.bg-bgGrey{
		@apply pt-10;
	}

	.play-btn {
		transform: translate(-50%, -50%);
	}

	@screen lg { 
		//padding-bottom: 490px;

		min-height: 490px;

		.container {
			//transform: translateY(-50%);
		}
	}

	&.img-right {

		.container {
			> div {
				@screen lg {
					@apply ml-0; 
				}
			}
		}

		.img-wrap {
			@screen lg {
				@apply right-0 pl-6;
			}

			@screen xl {
				@apply pl-12;
			}

			@screen xxl {
				@apply pl-24;
			}
		}
	}

	&.img-left {

		.container {
			> div {
				@screen lg {
					@apply mr-0; 
				}
			}
		}

		.img-wrap {
			@screen lg {
				@apply left-0 pr-6;
			}

			@screen xl {
				@apply pr-12;
			}
			
			@screen xxl {
				@apply pr-24;
			}
		}
	}
}

.section-image-text-portrait {
	&__wrapper {
		@screen lg {
			@apply flex justify-between items-center;
		}
	}

	&.img-left {

		.img-wrap {
			@screen lg {
				@apply pr-6;
			}

			@screen xl {
				@apply pr-12;
			}

			@screen xxl {
				@apply pr-24;
			}
		}
	}

	&.img-right {

		.section-image-text-portrait__wrapper {
			@screen lg {
				@apply flex-row-reverse;
			}
		}

		.img-wrap {
			@screen lg {
				@apply pl-6;
			}

			@screen xl {
				@apply pl-12;
			}

			@screen xxl {
				@apply pl-24;
			}
		}
	}
}

.section-image-text-blog {
	&__wrapper {
		@screen lg {
			@apply flex justify-between items-center;
		}
	}

	&.img-left {

		.img-wrap {
			@screen lg {
				@apply pr-4;
			}
		}
		.copy-wrap {
			@screen lg {
				@apply pl-4;
			}
		}
	}

	&.img-right {

		.section-image-text-blog__wrapper {
			@screen lg {
				@apply flex-row-reverse;
			}
		}

		.img-wrap {
			@screen lg {
				@apply pl-4;
			}
		}
		.copy-wrap {
			@screen lg {
				@apply pr-4;
			}
		}
	}
}