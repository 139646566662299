.section-blog-tiles {

	&__wrapper {
		@media screen and (max-width: 470px){
			min-width: 100% !important;
		}
	}

	.tile {
		@apply pb-6;

		@screen md {
			@apply pb-20;
		}

		&:last-child {
			@apply pb-0;
		}

		h1,h2,h3,h4,h5 {
			@apply mb-4;
		}

		@media screen and (max-width: 470px){
			width: 100% !important;
		}

		@apply bg-white absolute;

		img {
			@apply transition-all duration-300;
		}

		a {
			@apply block;

			&:hover {
				box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);

				img {
					transform: scale(1.1);
				}
			}
		}

		.tags {
			@apply mt-0 mb-2; 

			p {
				@apply mb-0;
			}
		}

		p {
			@apply text-dkGrey;

			&.read-time {
				@apply mb-6;
			}
		}

		&:last-child {
			@apply mb-0;
		}

		@screen lg {
			width: 33.3333 !important;
			
			@apply px-4 mb-16;
		}
	}

}