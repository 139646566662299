.gallery-swiper {
	@apply relative pb-12;

	.swiper-wrapper {

		@apply items-center;

		.swiper-slide {
			width: 70% !important;
			transform: scale(.8);

			&-active {
				transform: scale(1);
				box-shadow: 0px 6px 16px -8px rgba(0,0,0,0.75);

				// @screen lg {
				// 	box-shadow: 0px 6px 16px -8px rgba(0,0,0,0.75);
				// }

				.overlay {
					@apply opacity-0;
				}
			}

			.play-btn {
				transform: translate(-50%, -50%);
			}
		}

	}

	.swiper-pagination.swiper-pagination-bullets {
		@apply bottom-0 mb-0 flex justify-center items-center;

		.swiper-pagination-bullet {
			@apply w-2 h-2 bg-white rounded-none opacity-100;

			&:hover {
				@apply bg-yellow;
			}
		}

		.swiper-pagination-bullet-active {
			@apply w-3 h-3 bg-yellow;
		}
	}

}