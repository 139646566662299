.section-gallery-swiper {

	.gallery-swiper-block {

		@apply pt-0 relative;

		@screen lg {
			@apply pt-20;
		}

		.swiper-slide {
			@apply relative transition-all duration-300 bg-white;
			width: 340px !important;

			&-prev {
				@apply opacity-40;
			}

			@screen xl {
				width: calc(44% - 21px) !important;

				&-next {
					+ .swiper-slide + .swiper-slide {
						@apply opacity-40;
					}
				}
			}

			&:hover {
				transform: translateY(-20px);
				box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
			}

			.play-btn {
				transform: translate(-50%, -50%);
			}

		}

		.swiper-button-prev,
		.swiper-button-next {
			@apply bottom-auto text-black bg-white transition-all duration-300 w-12 h-12 flex items-center justify-center top-0 mt-0;
			
			@screen lg {
				@apply w-16 h-16;
			}

			&:after {
				@apply hidden;
			}

			&:hover {
				@apply bg-yellow;	
			}
		}

		.swiper-button-prev {
			@apply right-0 left-auto mr-20;
			margin-top: -70px;

			@screen lg { 
				@apply -mt-12;
			}
		}

		.swiper-button-next {
			@apply right-0 left-auto;
			margin-top: -70px;

			@screen lg { 
				@apply -mt-12;
			}
		}

	}
}