.secondary-footer {

	.img-wrap {
		img {
			@screen lg {
				height: 450px;
			}

			@screen xl {
				height: 642px;
			}
		}
	}
	.container {
		@apply -mt-10 relative z-10;

		@screen md { 
			@apply -mt-14;
		}

		@screen xl {
			@apply absolute left-2/4 bottom-0 flex justify-end;
			transform: translateX(-50%);
		}
	}
}