.mobile-btn {
	@apply bg-yellow text-black font-ibm text-sm text-center pt-3 pb-4 uppercase tracking-wider font-medium;

	span {
		@apply relative;

		&:before {
			content: "";
			@apply absolute bottom-0 w-full bg-black h-px -mb-1;
		}
	}
}

#app {
	.navigation-overlay {
		@apply hidden;
	}

	&.active {
		.navigation-overlay {
			@apply block;
		}
	}
}

.section-header {


	.logo-wrap {
		@apply left-2/4 bottom-0 mb-7;
		transform: translateX(-50%);

		@screen xl { 
			@apply mb-8;
		}

		a {
			@screen xxxl {  
				bottom: 14px;
				left: -90px;
			}

			@screen xxxxl {  
				left: -150px;
			}
		}
	}

	.logo {
		@apply w-40;

		@screen lg { 
			@apply w-56;
		}

		@screen xl { 
			@apply w-60;
		}
	}

   	.main-nav {
   		@apply flex items-center justify-end;

   		@screen xl {
   			@apply justify-end;
   		}

   		> li {
   			@apply text-sm font-ibm tracking-wider font-medium ml-6;

   			@screen xl {
   				@apply ml-8 text-base;
   			}

   			> a {
   				@apply text-white pb-6 block relative uppercase;

   				@screen xxxl {
   					@apply pb-10;
   				}

   				&:after {
					content: "";
					@apply absolute w-full h-0.5 bg-yellow bottom-0 left-0 mb-4 opacity-0 transition-all duration-300;

					@screen xxxl { 
						@apply mb-6;
					}
				}

				&:hover,
				&.active,
				&.active-menu {
					&:after {
						@apply opacity-100;
					}
				}
   			}
   		}

   		&__item--children {
			> a {
				@apply relative pr-4;
				
				&:before {
					content: "";
					background-image: url('/img/svg/chevron-down-regular-white.svg');
					@apply absolute right-0 top-0 bg-no-repeat w-3 h-3 bg-center bg-contain mt-1.5 transition-all duration-300;
				}

				&.active-menu { 
					&:before {
						transform: rotate(180deg);
					}
				}
			}
		}
   	}

   	.secondary-nav {
   		p {
   			@apply text-white;
   		}
   	}

   	.mega-menu-wrapper {
   		@apply absolute z-40 bg-white left-0 w-full py-10 hidden opacity-0 transition-all duration-300;

   		&.active {
   			@apply block opacity-100;
   			box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
   		}

   		.close-panel-btn {
   			@apply absolute top-0 right-0 w-14 h-14 text-black bg-yellow flex items-center justify-center;

   			&:hover {
   				@apply bg-black text-yellow;
   			}
   		}

   		.module {
   			a {
   				&:hover {
					.arrow-only {
						span {
			                &.icon {
			                    &:after {
			                        animation: buttonAnimation 0.2s 1 ease-out;
			                    }
			                }
		            	}
					}

					@apply text-dkBlue;
				}
   			}

   			&:nth-child(n+4) {
   				@apply mt-8;
   			}
   		}
   	}
}