.section-full-img {
	.img-wrap {
		img {
			@screen lg {
				height: 450px;
			}

			@screen xl {
				height: 642px;
			}
		}
	}
	.container {
		@apply -mt-10 relative z-10;

		@screen md {
			@apply -mt-14;
		}

		@screen lg {
			@apply absolute left-2/4 bottom-0 flex;
			transform: translateX(-50%);
		}

		.highlight, h1,h2,h3,h4,h5,h6,p, ul,li ol,li {
			@apply text-white;
		}

		ul li::before {
			@apply bg-white;
		}
	}

	&.content-right {
		.container {
			@screen lg {
				@apply justify-end;

				.content {
					@apply mr-0;
				}
			}
		}
	}

	&.content-left {
		.container {
			@screen lg {
				@apply justify-start;

				.content {
					@apply ml-0;
				}
			}
		}
	}

	.btn-wrap {
		@apply flex flex-col items-start;

		@screen sm {
			@apply flex-row;
		}

		@screen lg {
			@apply flex-col;
		}

		@screen xl {
			@apply pt-8 flex-row;
		}

		.button {
			&:last-child {
				@apply mt-6;

				@screen sm {
					@apply mt-0 ml-6;
				}

				@screen lg {
					@apply ml-0 mt-6;
				}

				@screen xl {
					@apply ml-10 mt-4;
				}
			}

			&:first-child {
				@screen sm {
					@apply mt-0 ml-0;
				}
			}
		}
	}
}

#blocks {
    .section-full-image:last-of-type {
        @apply pb-20;
        @screen lg {
            @apply pb-32;
        }
    }
    .section-full-image + .section-full-image {
        @apply pt-10;
        @screen lg {
            @apply pt-16;
        }
    }
}
