.section-related-swiper {

	.related-swiper {

		@apply pt-20 relative;

		.swiper-slide {
			@apply relative transition-all duration-300 bg-white;
			width: 340px !important;

			@screen xl {
				width: calc(33.3333% - 21px) !important;

				&-next {
					+ .swiper-slide + .swiper-slide {
						@apply opacity-40;
					}
				}
			}

			h1,h2,h3,h4,h5 {
				@apply mb-4;
			}

			a {
				@apply block;

				&:hover {
					box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
				}
			}

			.tags {
				@apply mt-0 mb-2; 

				p {
					@apply mb-0;
				}
			}

			p {
				@apply text-dkGrey;

				&.read-time {
					@apply mb-6;
				}
			}

		}

		// .copy-wrap {
		// 	@apply px-6 py-8;

		// 	@screen xl {
		// 		@apply px-10 py-8;
		// 	}

		// 	p {
		// 		@apply text-border;
		// 	}

		// 	ul {
		//         @apply my-4 pl-4 font-ibm;

		//         &:last-child {
		//             @apply mb-0;
		//         }

		//         li {
		//             @apply mb-2 relative text-black;

		//             @screen lg { 
		//                 @apply text-lg;
		//             }

		//             &:before {
		//                 content: "";
		//                 @apply absolute bg-border rounded-full;
		//                 width: 4px;
		//                 height: 4px;
		//                 top: 8px;
		//                 left: -16px;

		//                 @screen lg {  
		//                     top: 13px;
		//                 }
		//             }
		//         }
		//     }

		//     ul.tick-list {
		//         @apply my-4 pl-6 font-ibm;

		//         &:last-child {
		//             @apply mb-0;
		//         }

		//         li {
		//             @apply mb-2 relative text-black;

		//             @screen lg { 
		//                 @apply text-lg;
		//             }

		//             &:before {
		//                 content: "";
		//                 @apply absolute bg-no-repeat bg-cover bg-transparent;
		//                 background-image: url('/img/svg/check-regular.svg');
		//                 width: 12px;
		//                 height: 10px;
		//                 top: 6px;
		//                 left: -20px;

		//                 @screen lg {  
		//                     top: 10px;
		//                 }
		//             }
		//         }
		//     }

		//     ol {
		//         @apply font-ibm;
		//         counter-reset: my-awesome-counter;

		//         li {
		//             @apply text-black mb-2;
		//             counter-increment: my-awesome-counter;

		//             @screen lg { 
		//                 @apply text-lg;
		//             }

		//             &:before {
		//                 @apply pr-2 inline-block;
		//                 content: counter(my-awesome-counter) ". ";

		//                 @screen lg {  
		//                     width: 30px;
		//                 }
		//             }
		//         }
		//     }
		// }

		.swiper-button-prev,
		.swiper-button-next {
			@apply bottom-auto text-black bg-white transition-all duration-300 w-12 h-12 flex items-center justify-center top-0 mt-0;
			
			@screen lg {
				@apply w-16 h-16;
			}

			&:after {
				@apply hidden;
			}

			&:hover {
				@apply bg-yellow;	
			}
		}

		.swiper-button-prev {
			@apply left-0 ml-6;

			@screen lg {  
				@apply right-0 left-auto mr-20 -mt-16;
			}
		}

		.swiper-button-next {
			@apply left-0 right-auto ml-20;
			
			@screen lg { 
				@apply right-0 left-auto -mt-16;
			}

		}

	}
}