.container {
	max-width: 1364px !important;
}

section {
	scroll-margin-top: 0px;

    @screen lg {
        scroll-margin-top: 146px;
    }

    @screen xl { 
        scroll-margin-top: 154px;
    }

     @screen xxxl { 
        scroll-margin-top: 149px;
    }
}

.modal .goog-te-combo {
	background-image: url(/img/svg/select.svg);
    background-size: 40px;
    background-position: right 5px center;

	@apply w-full font-ibm border border-black border-solid text-black py-3 px-4 text-lg bg-white;

    &:focus {
        @apply border-yellow outline-none shadow-none ring-0;
    }

    @screen lg {
        @apply text-lg;
    }
}
