.section-image-video {
	max-height: 550px;
	min-height: 500px;
	@screen lg {
		@apply max-h-full h-screen;
		min-height: 600px;
		height: calc(100vh - 148px);
	}

	@screen xl {
		height: calc(100vh - 152px);
		min-height: 700px;
	}

	@screen xxxl {
		height: calc(100vh - 145.5px);
	}

	.hero-img {
		max-height: 550px;

		@screen lg {
			@apply max-h-full h-screen;
			min-height: 600px;
		}
	}

	.video-wrap {

		@apply max-h-full h-screen overflow-hidden relative;

		@screen lg {
			height: calc(100vh - 148px);
			min-height: 600px;
		}

		@screen xl {
			height: calc(100vh - 152px);
			min-height: 700px;
		}

		@screen xxxl {
			height: calc(100vh - 145.5px);
		}

		video {
			object-fit: cover !important;
		}

		.wistia_embed {
			width: 2900px !important;
			height: 100% !important;
			position: absolute !important;
			transform: translate(-50%, -50%);

			@apply max-h-full left-2/4 top-2/4;
			min-height: 600px;
		}

		.w-bpb-wrapper,
		.w-vulcan-v2-button,
		.w-bottom-bar {
			display: none !important;
		}
	}

	.container {
		transform: translate(-50%, -50%);
	}

	.btn-wrap {
		@apply flex flex-col items-start;

		@screen lg { 
			@apply pt-8;
		}

		.button {
			&:last-child {
				@apply mt-4;
			}

			&:first-child { 
				@screen sm { 
					@apply mt-0;
				}
			}
		}
	}
}