.section-blog-header {
	.share-btn {
		 li {
		 	a {
		 		@apply bg-black text-white flex items-center justify-center cursor-pointer;

		 		&:hover {
		 			@apply bg-yellow;
		 		}
		 	}
		 }
	}
}