.section-video-block {
	&__wrapper {

		@apply overflow-hidden relative;
		padding-top: 56%;

		.wistia_embed {
			width: 100% !important;
			height: 100% !important;
			position: absolute !important;
			transform: translate(-50%, -50%);
			@apply max-h-full left-2/4 top-2/4;
		}

		.w-bpb-wrapper,
		.w-big-play-button {

			overflow: hidden;

			div {
				background-color: #FFF200 !important;
				background-image: url('/img/svg/play.svg');
				background-repeat: no-repeat;
				background-position: center;
				background-size: 20%;
			}

			svg {
				display: none !important;
			}

		}

	}
}