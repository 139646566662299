.section-tags {
	&__wrapper {

		ul {

			li {
				@apply font-ibm uppercase text-sm tracking-wider font-medium mb-2;

				@screen lg {
					@apply w-1/3 px-4 mb-4;
				}

				@screen xl { 
					@apply text-base;
				}

				a {
					@apply px-4 relative py-4 block border-b border-solid border-borderGrey;

					@screen xl { 
						@apply py-5;
					}

					&:before {
						content: "";
						@apply absolute right-0 top-0 w-4 h-4 bg-no-repeat mt-5 mr-4;
						background: url('../../img/svg/arrow-right-light.svg');
		                background-size: 16px;
		                background-position: center; 

		                @screen xl {  
		                	@apply mt-6;
		                }
					}

					&:hover {
						@apply bg-yellow border-yellow;
					}
				}
			}
		}
	}
}