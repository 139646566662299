.section-two-tile {

	.tile a {
		@apply block bg-ltGrey;

		&:hover {
			@apply bg-highlightGrey;
			
			.arrow-only {
				span {
	                &.icon {
	                    &:after {
	                        animation: buttonAnimation 0.2s 1 ease-out;
	                    }
	                }
            	}
			}

			.copy-wrap { 
				@apply bg-highlightGrey;
			}
		}
	}

	.copy-wrap {
		@apply px-6 py-8 bg-ltGrey;

		@screen xl {
			@apply px-10 py-8;
		}

		p {
			@apply text-border;
		}
	    
	}

	&.bg-black {
		p,h1,h2,h3,h4,h5 {
			@apply text-white;
		}

		.copy-wrap {
			p,h1,h2,h3,h4,h5 {
				@apply text-black;

				&:last-child {
					@apply mb-0;
				}
			}
		}
	}

	&.img-top {
		.tile-link a, .tile-link{
			@apply flex flex-col flex-col-reverse;
		}
		@media (min-width: 1024px) {
			.h1, h1 {
			  font-size: calc(2.8 * 15px);
			}
		}
		@media (min-width: 1024px) {
			.h2, h2 {
			  font-size: calc(2 * 15px);
			}
		}
	}
}