// App
// Includes styles that are likely to be changed/compiled often

/* purgecss start ignore */
@import "global/header";
@import "global/footer";
@import "global/custom";
@import "global/typography";

@import "sections/secondary-footer";
@import "sections/logo-list";
@import "sections/tiles";
@import "sections/blog-tiles";
@import "sections/full-img";
@import "sections/tile-swiper";
@import "sections/image-video";
@import "sections/image-banner";
@import "sections/image-text";
@import "sections/product-spotlight";
@import "sections/tabs";

@import "sections/product-menu";
@import "sections/tags";
@import "sections/blog-header";

@import "sections/related-swiper";
@import "sections/gallery-swiper-block";
@import "sections/two-tile";
@import "sections/video-block";

@import "partials/accordion";
@import "partials/gallery-swiper";
@import "partials/modal";

///@import "partials/modal";
//@import "partials/animation";
/* purgecss end ignore */
