.section-tile-swiper {

	.swiper-link {
		@apply block bg-ltGrey;

		a {
			&:hover {
				@apply bg-highlightGrey;

				.arrow-only {
					span {
		                &.icon {
		                    &:after {
		                        animation: buttonAnimation 0.2s 1 ease-out;
		                    }
		                }
	            	}
				}
			}
		}
	}

	&.img-bottom {
		.swiper-link a, .swiper-link{
			@apply flex flex-col flex-col-reverse;
		}
	}

	.title-wrap,
	.copy-wrap {
		h1,h2,h3,h4,h5 {

	        @apply mb-6 text-black;

	        &:first-child {
	            @apply mt-0;
	        }

	        &:last-child {
	            @apply mb-0;
	        }
    	}
	}

	.title-wrap {
		@apply text-dkGrey;
	}

	.tile-swiper {

		&.swiper-container {
			@apply overflow-visible;
		}

		@apply pt-20 relative w-full ml-6 pr-6;

		@screen lg {
			@apply ml-0 pr-0;
		}

		a {
			@apply block h-full;
		}

		.swiper-slide {
			@apply relative transition-all duration-300 h-auto mr-4;

			width: 340px !important;

			@screen xl {
				@apply mr-8;

				&:last-child {
					@apply mr-0;
				}

				width: calc(33.3333% - 21px) !important;

				&-next {
					+ .swiper-slide + .swiper-slide {
						@apply opacity-40;
					}

					+ .swiper-slide + .swiper-slide + .swiper-slide {
						@apply opacity-40;
					}
				}
			}

		}

		.copy-wrap {
			@apply px-6 py-8;

			@screen lg {
				@apply pr-14;
			}

			@screen xl {
				@apply px-10 py-8;
			}

			p {
				@apply text-border;
			}

			ul {
		        @apply my-4 pl-4 font-ibm;

		        &:last-child {
		            @apply mb-0;
		        }

		        li {
		            @apply mb-2 relative text-black;

		            @screen lg {
		                @apply text-lg;
		            }

		            &:before {
		                content: "";
		                @apply absolute bg-border rounded-full;
		                width: 4px;
		                height: 4px;
		                top: 8px;
		                left: -16px;

		                @screen lg {
		                    top: 13px;
		                }
		            }
		        }
		    }

		    ul.tick-list {
		        @apply my-4 pl-6 font-ibm;

		        &:last-child {
		            @apply mb-0;
		        }

		        li {
		            @apply mb-2 relative text-black;

		            @screen lg {
		                @apply text-lg;
		            }

		            &:before {
		                content: "";
		                @apply absolute bg-no-repeat bg-cover bg-transparent;
		                background-image: url('/img/svg/check-regular.svg');
		                width: 12px;
		                height: 10px;
		                top: 6px;
		                left: -20px;

		                @screen lg {
		                    top: 10px;
		                }
		            }
		        }
		    }

		    ol {
		        @apply font-ibm;
		        counter-reset: my-awesome-counter;

		        li {
		            @apply text-black mb-2;
		            counter-increment: my-awesome-counter;

		            @screen lg {
		                @apply text-lg;
		            }

		            &:before {
		                @apply pr-2 inline-block;
		                content: counter(my-awesome-counter) ". ";

		                @screen lg {
		                    width: 30px;
		                }
		            }
		        }
		    }
		}

		.swiper-button-prev,
		.swiper-button-next {
			@apply bottom-auto text-black bg-yellow transition-all duration-300 w-12 h-12 flex items-center justify-center top-0 mt-0;

			@screen lg {
				@apply w-16 h-16;

				&.hide,
				&.swiper-button-disabled {
					display: none !important;
				}
			}

			&:after {
				@apply hidden;
			}

            svg {
                @apply transition-all;
            }

		}

		.swiper-button-prev {
			@apply left-0 ml-6;

			@screen lg {
				@apply right-0 left-auto mr-20 -mt-16;
			}

            &:hover {
                svg {
                    animation: bigButtonAnimationReverse 0.25s 1 ease-out;
                }
			}
		}

		.swiper-button-next {
			@apply left-0 right-auto ml-20;

			@screen lg {
				@apply right-0 left-auto -mt-16;
			}

            &:hover {
                svg {
                    animation: bigButtonAnimation 0.25s 1 ease-out;
                }
			}

		}

	}
}

.section-tile-swiper.bg-bgGrey  {
    .swiper-button-prev,
    .swiper-button-next {
        @apply bg-white;
    }
}
