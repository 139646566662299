.section-tabs {

    .b-tab {
        display: none;
        @apply w-full bg-dkBlue pt-8 pb-14;

        @screen lg {
        	@apply py-20;
        }

        .gallery-wrapper {
            max-width: 100% !important;
        }
    }

    .b-tab.active {
        display: block;
    }

    .desktop-tabs {
    	.b-tab {
    		@apply pt-0;

    		@screen lg {
    			@apply pb-0;
    		}
    	}
    }

    .toggle-list {
    	@apply border border-solid border-white inline-flex;

    	li {
    		@apply flex-grow-0 uppercase text-sm font-ibm tracking-wider font-medium;

    		a {
    			@apply px-6 py-4 bg-yellow text-dkBlue block;

    			&:hover,
    			&.active {
    				@apply bg-dkBlue text-white;
    			}
    		}

    		&:first-child { 
    			a {
    				@apply border-r border-solid border-white;
    			}
    		}
    	}
    }

    &__content {  
  		table {
  			tr {
  				td {
  					@apply border-t-0 border-solid border-b border-borderGrey text-white py-6 font-ibm text-sm align-top;
  					min-width: 300px;

  					@screen lg {
						min-width: 0px;
  						@apply text-base;
  					}

  					&:first-child {
  						@apply uppercase font-bold tracking-wider;
  						
  						@screen lg {
  							min-width: 350px;
  						}
  					}

  					ul {
  						li {
  							@apply text-base;

  							@screen lg {  
  								@apply text-lg;
  							}
  						}
  					}

  				}

                &:last-child {
                    td {
                        @apply border-b-0;
                    }
                }
  			}
  		}
    }

    &__list { 
        @apply overflow-x-auto whitespace-nowrap flex;

        @screen lg { 
            @apply flex justify-start whitespace-normal;
        }

        .item {
            @apply inline-block w-full mr-0.5;

            @screen lg { 
                @apply w-auto flex-grow-0 mr-1;
            }

            .b-nav-tab {
                @apply text-center py-4 px-2 text-dkBlue font-semibold font-ibm bg-yellow text-base h-full flex flex-col justify-center transition-all duration-300 uppercase;

                &:hover {
                   @apply text-yellow bg-dkBlue;
                }

                @screen lg {  
                    @apply py-5 px-10;
                }

                @screen xl { 
                    @apply text-xl;
                }
            }

            .b-nav-tab.active {
                @apply text-yellow bg-dkBlue;

                &:hover {
                   @apply text-yellow bg-dkBlue;
                }
            }
        }
    }

    &__sub-list {
    	.item {
    		.b-nav-tab { 
    			@apply uppercase block font-ibm relative pr-10 py-6 pl-0 text-white font-semibold border-b-2 border-solid border-borderGrey tracking-wider;

    			 &:after {
		            @apply absolute right-0 w-6 h-6 bg-no-repeat bg-center top-1/2 transition-all duration-300;
		            transform: translateY(-50%);
		            content: "";
		            background-image: url('../../img/svg/arrow-right-light-white.svg');
		            background-repeat: no-repeat;
		            background-size: 16px;
		        }

		        &:hover { 
		        	@apply text-yellow;

		        	&:after {
                		background-image: url('../../img/svg/arrow-left-light-yellow.svg');
            		}
		        }
    		}

    		.b-nav-tab.active { 
    			@apply text-yellow font-bold;

    			&:after {
                	background-image: url('../../img/svg/arrow-left-light-yellow.svg');
            	}
    		}
    	}
    }


}