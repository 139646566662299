// Footer

.footer {

	.logo {
		width: 144px;

		@screen lg {
			width: 240px;
		}
	}
	
	.social-list {
		li {
			a {
				@apply text-white;

				&:hover {
					@apply text-yellow;
				}
			}
		}
	}

	.primary-list {
		@apply pl-2;

		@screen md { 
			@apply flex; 
		}

		li {
			@apply font-ibm text-xs uppercase mb-3 font-medium tracking-wider uppercase;

			@screen md {  
				@apply ml-6;
			}

			@screen lg {  
				@apply mb-0 text-base;
			}

			a {
				@apply text-white relative;

				&:after {
					content: "";
					@apply absolute w-full h-0.5 bg-yellow bottom-0 left-0 -mb-2 opacity-0 transition-all duration-300;

					@screen xxl { 
						@apply -mb-2.5;
					}

					@screen xxxl { 
						@apply -mb-4;
					}
				}

				&:hover {
					&:after {
						@apply opacity-100;
					}
				}
			}
		}
	}

}
