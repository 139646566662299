.section-logo-list {
	&__list {
		@apply flex justify-center flex-wrap items-center;

		@screen lg {
			@apply justify-between;
		}

		img {
			transform: scale(0.86);

			@screen lg { 
				transform: scale(1);
			}
		}

		li {
			@apply px-4 py-4;

			@screen lg { 
				@apply p-0;
			}
		}
	}	
}