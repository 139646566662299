.section-product-menu {
	@apply transition-all duration-300 top-0; 

	.accordion {
		@apply border border-solid border-black p-3;

		&__link {
			@apply font-ibm font-medium text-sm uppercase text-black tracking-wider relative w-full block;

			&:before {
				content: "";
				@apply absolute right-0 top-0 w-4 h-4 bg-no-repeat mt-0.5;
				background: url('../../img/svg/arrow-down-light.svg');
                background-size: 16px;
                background-position: center; 
			}

			&.is-active {
				&:before { 
					transform: rotate(180deg);
				}
			}
		}

		&__content {

			ul {
				@apply py-2 pl-4;

				li {
					@apply text-sm;

					a {
						@apply py-1 block;
					}
				}
			}

			&.is-active {
				&:after {
					@apply hidden;
				}
			}
		}
	}

	.desktop-list {
		li {
			@apply font-medium uppercase tracking-wider text-sm font-ibm pr-6;

			@screen xl {
				@apply pr-12 text-base;
			}

			a {
				@apply py-5 inline-block text-white relative;

				&:after {
					content: "";
					@apply absolute w-full h-0.5 bg-yellow bottom-0 left-0 mb-0 opacity-0 transition-all duration-300;
				}

				&:hover {
					&:after {
						@apply opacity-100;
					}
				}
			}
		}
	}

}

body.fixed-nav {
	.section-product-menu { 
		@screen lg {
			@apply sticky z-20; 
			top: 148px;
		}

		@screen xl { 
			top: 152px;
		}

		@screen xxxl { 
			top: 145px;
		}
	}
}