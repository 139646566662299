.section-product-spotlight {
	&__wrapper {
		@screen lg {
			@apply flex justify-between items-center;
		}
	}

	.button:nth-of-type(2){
		margin-left: 2rem;
	}
	.button:nth-of-type(3){
		margin-left: 2rem;
	}
	.button.secondary {
		@apply text-black;
		text-decoration:none;
	}

	&.img-left {

		.img-wrap {
			@screen lg {
				@apply pr-6;
			}

			@screen xl {
				@apply pr-12;
			}

			@screen xxl {
				@apply pr-24;
			}
		}
	}

	&.img-right {

		.section-product-spotlight__wrapper {
			@screen lg {
				@apply flex-row-reverse;
			}
		}

		.img-wrap {
			@screen lg {
				@apply pl-6;
			}

			@screen xl {
				@apply pl-12;
			}

			@screen xxl {
				@apply pl-24;
			}
		}
	}
}