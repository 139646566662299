
.accordion {
	.accordion__item {
    	@apply relative border-b-2 border-solid border-borderGrey;
	}

    .accordion__item:last-of-type {
        @apply mb-0;
    }

    .accordion__content p:last-child {
        @apply mb-0;
    }

    .accordion__title {
        @apply relative pr-10 py-6 pl-6;

        span {
        	@apply text-sm uppercase text-white font-semibold tracking-wider font-ibm;
        }

        &:after {
            @apply absolute right-0 w-6 h-6 bg-no-repeat bg-center top-1/2 transition-all duration-300;
            transform: translateY(-50%);
            content: "";
            background-image: url('../../img/svg/arrow-right-light-white.svg');
            background-repeat: no-repeat;
            background-size: 12px;
        }

        &:hover {
        	span { 
        		@apply text-yellow;
        	}
            &:after {
                background-image: url('../../img/svg/arrow-right-light-yellow.svg');
            }
        }

        &.is-active {

        	span {
        		@apply text-yellow font-bold;
        	}

            &:after {
                background-image: url('../../img/svg/arrow-left-light-yellow.svg');
            }

            &:hover {
                &:after {
                    background-image: url('../../img/svg/arrow-left-light-yellow.svg');
                }
            }
        }
    }

    &-block {
        .accordion__item {
            @apply border-ltGrey;
        }

        .accordion__title {
            p {
                @apply uppercase font-medium tracking-wider;
            }

            &:after {
                background-image: url('../../img/svg/arrow-down-light.svg');
                background-size: 16px;
            }

            &:hover { 
                &:after { 
                    background-image: url('../../img/svg/arrow-down-light.svg');
                    background-size: 16px;
                }
            }

            &.is-active {
                p {
                    @apply uppercase font-bold;
                }

                &:after { 
                    background-image: url('../../img/svg/arrow-up-light.svg');
                    background-size: 16px;
                }

                &:hover { 
                    &:after { 
                        background-image: url('../../img/svg/arrow-up-light.svg');
                        background-size: 16px;
                    }
                }
            }
        }

        .accordion__content.is-active { 
            &:after {
                @apply bg-black;
            } 
        }
    }
}

.accordion__content {
    max-height: 0;
	@apply overflow-hidden opacity-0 transition-all duration-300 ease-in-out relative;
}

.accordion__content.is-active {
    max-height: 1000px;
    @apply opacity-100 overflow-visible;

    &:after {
    	content: "";
    	@apply w-full h-0.5 bg-yellow absolute bottom-0 -mb-0.5 z-10;
    }
}



