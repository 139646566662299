.section-tiles {

	.tile {
		@apply mt-12;

		&:first-child {
			@apply mt-0;
		}

		@screen lg {
			@apply px-4 w-1/3 mt-16;

			&:nth-child(1),
			&:nth-child(2),
			&:nth-child(3) {
				@apply mt-0;
			}
		}
	}

}